import React from 'react';
import aiML from '../icons/ai_ml.jpg';  // Import the image based on the given directory structure

function JumbotronClients() {
  return (
    <div className="jumbotron">
        <img src={aiML} alt="Supply Chain Image" className="jumbotron-video"/>
        {/*<video autoPlay loop muted className="jumbotron-video">
            <source src={hdVideo} type="video/mp4" />
            Your browser does not support the video tag.
        </video>*/}
        <div className="heading">Our Clients: We provide Tailored Solutions and Support
            <div className="content">Empowering Your Business Objectives,</div>
            <div className="content">With Bespoke Strategies and Expert Guidance,</div>
            <div className="content">Ensuring Success in Today's Competitive Landscape.</div>          
        </div>
    </div>
  );
}

export default JumbotronClients;

