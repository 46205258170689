import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import JumbotronOthers from './assets/components/JumbotronOthers.js';
import InternsPresentPastMeetSmart from './assets/components/InternsPresentPastMeetSmart.js';
import InternsPresentPastMeetSmartTwo from './assets/components/InternsPresentPastMeetSmartTwo.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import MeetSmartSlider from './assets/components/MeetSmartSlider.js';
import JumbotronHomeGoals from './assets/components/JumbotronHomeGoals.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function OthersPage() {
  return (
    <div className="App">
      <Navbar />
      <JumbotronOthers />
      <InternsPresentPastMeetSmart />
      <MeetSmartSlider />
      <InternsPresentPastMeetSmartTwo />
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default OthersPage;
