import React from 'react';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import AboutUsJumbo from './assets/components/AboutUsJumbo.js';
import AboutUSMissionVision from './assets/components/AboutUSMissionVision.js'
import AboutUsTeam from './assets/components/AboutUsTeam.js'
import AboutUsTeamDetails from './assets/components/AboutUsTeamDetails.js'
import AboutUsPraveen from './assets/components/AboutUsPraveen.js'
import AboutUsLookingAhead from './assets/components/AboutUsLookingAhead.js'
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import AboutUSMissionVisionTwo from './assets/components/AboutUSMissionVision2.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function AboutUsPage() {
  return (
    <div className="App">
      <Navbar />
      <Jumbotron />
      <AboutUsJumbo />
      <AboutUSMissionVision />
      <AboutUSMissionVisionTwo/>
      <AboutUsTeam />
      <AboutUsPraveen />
      <AboutUsTeamDetails />
      {/*<AboutUsOurJourney />
      <AboutUsLookingAhead />*/}
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default AboutUsPage;
