import React from 'react';
import madasHealthLogo from '../icons/home-image.webp'  // Import the image based on the given directory structure
import wordCloud from '../icons/Logo-medispeak.jpg';

function JumbotronHomeMedispeak() {
  return (
    <div className="jumbotron-home">
            <div className="youtube-player">            
              <img 
                  width="560" 
                  height="315" 
                  src={wordCloud} 
                  alt="YouTube video placeholder" 
                  title="YouTube video player" 
                  style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
              />
              <div className="content-home"><h5>MediSpeakAI Clinical Trial Evaluation in Hyderabad, India.<br></br><br></br>
                Trial Participants: Over 105 doctor<br></br>
                Duration: 5-week clinical trial.<br></br>
                100% Endorsement: Unanimous approval from clinicians.<br></br><br></br>
                Impact on Diagnostic Accuracy: Highly praised for precise diagnostics.<br></br>
                Enhanced Clinical Documentation: Noted for comprehensive and efficient documentation.<br></br>
                Time Efficiency: Significant time savings across all medical professional levels.<br></br>
                Educational Value for Interns: Aids in history taking, targeted investigations, and accurate diagnoses.<br></br><br></br>
                Support for Junior Trainees: Provides essential information on differential diagnoses and management, reducing the need for senior consultations.<br></br>
                Benefit for Senior Doctors: Confirms diagnostic theories and optimizes documentation time.<br></br><br></br>
                Reduction in Medical Errors: Notably decreases errors and unnecessary investigations, especially beneficial for junior doctors.<br></br>
                Ideal for Developing Countries: Particularly effective in settings like India, offering substantial economic and social benefits.</h5></div>
                </div>            
        </div>       
     
  );
}

export default JumbotronHomeMedispeak;

