import React from 'react';
import keyStethImage from '../icons/team-gtech.webp'

function AboutUsTeam() {
  return (
    <div className="jumbotron-mv">   
        <div className="heading"><h5>Our Team</h5>
            <div className="content">
            At Madas Health AI, our team's diversity and expertise are our core strengths. Led by a visionary Chief 
            Data Scientist, our professionals include AI Specialists pushing medical technology boundaries, Cybersecurity 
            Consultants safeguarding sensitive data, and innovative Software Developers and Engineers. Quality Analysts ensure 
            our high standards, while our Project Manager efficiently oversees project execution. This dynamic ensemble drives 
            our commitment to healthcare innovation.
            </div>
        </div>  
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>                      
    </div>    
  );
}

export default AboutUsTeam;
