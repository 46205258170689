import React from 'react';
import keyStethImage from '../icons/lookingAhead.jpg'

function AboutUsLookingAhead() {
  return (
    <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading">Looking Ahead
            <div className="content">
            In the ever-evolving realm of medical AI, MedispeakAI emerges as a luminary on the ascend. 
            Even at our dawn, our relentless drive towards perfection, expanding knowledge horizons, and assimilating avant-garde 
            AI breakthroughs remain our cornerstone. MedispeakAI is more than a nascent innovation; it's an uprising, 
            set to sculpt the future of medical education and practice. Our journey has just begun, and the vistas ahead shimmer 
            with boundless promise.
            </div>
        </div>        
    </div>    
  );
}

export default AboutUsLookingAhead;
