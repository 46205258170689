import React from 'react';
import keyStethImage from '../icons/happy-patient.avif'   // Import the image based on the given directory structure

function JumbotronInterns() {
  return (
    <div className="jumbotron-mv-medispeak">
        <div className="heading"><strong>Enhancing Patient Experiences</strong>
            <div className="content">
              At MadasHealth, we believe in transforming healthcare for patients with the power of AI. 
              Our innovative solutions are crafted to enrich your healthcare journey, making it more personalized, 
              efficient, and effective. With MadasHealth, patients can experience quicker diagnoses, tailored treatment plans, 
              and streamlined care processes. Our AI technology works tirelessly to ensure that your health is monitored with 
              precision and empathy. Embrace a new era of empowered healthcare with MadasHealth, where every patient's wellbeing 
              is at the heart of technological advancement.
             </div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>  
  );
}

export default JumbotronInterns;

