import React from 'react';
import wordCloud from '../icons/digChakra.webp';

function JumbotronHomeDigitalChakra() {
  return (
    <div className="jumbotron-home-digital-chakra">
            <div className="youtube-player">          
              
              <div className="text-block-spec"><h2><strong>Digital Charaka: </strong>“Revolutionizing Healthcare in India with AI-Enabled Clinical Practice Guidelines"</h2></div>  
              <img 
                  width="6000" 
                  height="400" 
                  src={wordCloud} 
                  alt="YouTube video placeholder" 
                  title="YouTube video player" 
                  style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
              />    
              
              <div className="content-home">Revolutionize Indian healthcare with AI-driven Clinical Practice Guidelines (CPGs).<br></br><br></br>
                  Key Features:<br></br><br></br>
                  Standardize healthcare across India.<br></br>
                  Adapt Western CPGs and develop India-specific guidelines.<br></br><br></br>
                  Advantages:<br></br><br></br>
                  Bridge rural-urban healthcare divide.<br></br>
                  Equitable access to quality medical services.<br></br><br></br>
                  AI Use:<br></br><br></br>
                  Improve healthcare quality and safety.<br></br>
                  Reduce treatment variability and costs.<br></br><br></br>
                  Focus Area: Enhance family medicine training and practice.<br></br>
                  Public Health Impact: Address India's unique health challenges effectively.<br></br>
                  Family Medicine: Streamline care, reduce specialist referrals.<br></br><br></br>
                  Approach: Combine Western models with local needs analysis.<br></br>
                  Vision: Modernize Indian healthcare, align with global standards.</div>
            </div>            
        </div>       
     
  );
}

export default JumbotronHomeDigitalChakra;

