import React from 'react';
import inputWindow from '../icons/inputWindow.jpg';
import guideWindow from '../icons/guideWindow.jpg';
import summaryWindow from '../icons/summaryWindow.jpg';
import praveensir from '../icons/Dr.Madas.jpg'
import RajSir from '../icons/RajSir.webp'
import pravin2 from '../icons/pravin2.jpeg'

function AboutUsPraveen() {
    return (
        <div className="jumbotron-mv-team">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={praveensir} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

            <div className="heading">
                <h2 className="slider__caption"><strong>Dr.Eshwar Madas</strong></h2>
                <h5><strong>Founder & Group Chairman</strong></h5>
                <div className="content" style={{ textAlign: 'justify' }}>           
                Dr. Eshwar Madas, with 28 years of healthcare experience in New Zealand and Australia, is a seasoned professional 
                known for his expertise in primary and tertiary care. An Osmania Medical College graduate with additional training in 
                hospital administration from NIMS, Hyderabad, he furthered his education with a Master’s in health management and a 
                postgraduate diploma from New Zealand, along with a Fellowship from the Royal Australian College of General 
                Practitioners.
            </div>
                <div className="content" style={{ textAlign: 'justify' }}> 
                Founder of Madas Telehealth Pty Ltd, Dr. Madas is at the forefront of telemedicine and AI in healthcare, 
                creating AI-powered applications to improve primary care. With extensive experience in health informatics and 
                IT projects, gained from courses at Massey and Monash Universities, he brings a deep understanding of technology’s 
                role in healthcare.
            </div>
        </div>  
            <div className="content" style={{ textAlign: 'justify' }}>            
            His connection to the Indian community in Sydney, consulting regularly with Indian patients, gives him unique insights 
            into Indian medical practices. Dr. Madas's international experience and commitment to healthcare innovation position 
            him as an invaluable asset in advancing India's healthcare sector.
        </div>      
    </div> 
    );
}

export default AboutUsPraveen;
