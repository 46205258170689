import React from 'react';

// Importing components
import NavbarProd from './assets/components/navbar-prod.js';
import JumbotronProd from './assets/components/JumbotronProd.js';
import ProdServicesDetails from './assets/components/ProdServicesDetails';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';

// Importing CSS
import './assets/styles/header.css';  // assuming the styles folder is inside assets
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/textimonials.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function ProductsServices() {
  return (
    <div className="App">
      <NavbarProd />
      <JumbotronProd /> 
      <ProdServicesDetails />     
      <SignupComponent />
      <Footer />
      <Copyright />
    </div>
  );
}

export default ProductsServices;
