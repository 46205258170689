import React from 'react';
import keyStethImage from '../icons/collaboration.jpg';

function CollaborationDetails() {
  return (
    <div className="jumbotron-aboutUs">
        <div className="heading">Our Collaborations
            <div className="content">
                At GTechnologies Pty Ltd, we are proud to foster strategic collaborations with distinguished colleges across the United States, paving the way for educational advancement and industry readiness. Leveraging a symbiotic relationship, we facilitate comprehensive training modules focused on real-life projects, aiming to bridge the gap between academic learning and industry expectations.
                Our collaborations extend to various prestigious institutions, including Indiana University and Purdue University. Through these partnerships, we have the privilege of engaging with a dynamic cohort of students, nurturing their potential and fostering skills that are instrumental in shaping the leaders of tomorrow. Our programs are meticulously designed to equip students with the acumen and expertise required to excel in the ever-evolving industry landscape.
                Our mission remains steadfast: to cultivate a generation of professionals who are not only proficient in their respective fields but also embody a spirit of innovation and critical thinking. Together, we are shaping a future where education meets excellence, nurturing pioneers ready to make a tangible impact in the industry.
            </div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>    
  );
}

export default CollaborationDetails;
