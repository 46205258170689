import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import medicalImagry from '../icons/calInt.webp';
import voiceInte from '../icons/realsubs.webp';
import revClin from '../icons/intSum.webp';
import medCommu from '../icons/acAuto.webp';
import surgery from '../icons/seemInt.webp';
import prevFin from '../icons/sentAn.webp';
import edBridge from '../icons/cusLear.webp';
import prevFin2 from '../icons/mulsup.webp';
import edBridge2 from '../icons/secpri.webp';

function MeetSmartSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="testimonials-slider">
      <Slider {...settings}>        
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={voiceInte} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Real-Time Transcription:</h5> Efficiently transcribes discussions in any meeting setup - in-person or virtual, providing an accurate textual record of conversations.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={revClin} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Intelligent Summarization:</h5> Offers smart, concise summaries of meetings, highlighting essential points, decisions, and discussions, perfect for quick reviews and action planning.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medCommu} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Action Item Automation:</h5> Uniquely capable of automatically identifying and assigning tasks to participants, ensuring clear accountability and streamlined follow-up.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={surgery} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Seamless Integration:</h5> Integrates with your existing digital ecosystem, enhancing workflow without any disruptions, from scheduling tools to project management software.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medicalImagry} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Integration with Calendar and Task Management Tools:</h5> AI can integrate with other tools to schedule follow-up meetings, set reminders for action items, and update project management software.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={prevFin} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Sentiment Analysis:</h5> AI can analyze the tone and sentiment of the meeting to provide insights into team dynamics and participant engagement.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={edBridge} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Customization and Learning:</h5>Over time, AI can learn from user feedback and preferences to customize the output, making the minutes more relevant and useful.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={prevFin2} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Multilingual Support:</h5> Breaks down language barriers by offering transcription and summarization services in multiple languages, ideal for international teams (in development phase).</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={edBridge2} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Privacy and Security:</h5> Developed with the highest standards of confidentiality and data security, keeping your sensitive business information safe.</p>
              </div>
            </div>
        </div>
      </Slider>
    </div>
  );
}

export default MeetSmartSlider;
