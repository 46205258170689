import React from 'react';
import fontPage from '../icons/font_page.jpg';  // Import the image based on the given directory structure

function Jumbotron() {
  return (
    <div className="jumbotron-mv-main">
          <div className="heading"><strong>MADAS HEALTH ARTIFICIAL INTELLIGENCE (AI) INDIA</strong>
              <div className="content">Empowering Healthcare Professionals and Patients with Cutting-Edge AI Applications from Madas Telehealth Pty Ltd. </div>
        </div>
    </div>        
  );
}

export default Jumbotron;

