import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import fontPage from '../icons/manCome.png';
import wordCloud from '../icons/GTechnology-Logo-remove.png';
import RIC from '../icons/RIC.jpg';
function CollabDetails() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // Adjust the speed as per your requirement
    };

    // Inline styles for logos container and individual logos
    const logosContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
    };

    const logoStyle = {
        height: '250px',  // Reduced Size
        width: 'auto',
        margin: '0 50px',
    };

    return (
        <div className="testimonials">
            <div className="step-edu-specialist-main">
                <Slider {...settings}>
                    <div className="slide-content-spec">
                        <div className="text-block-spec">
                            <ul>
                                <h2>Transform Healthcare with AI: Madas Health AI Training Program</h2>
                                <p>Madas Health AI presents a distinctive 3-month short course designed to empower both healthcare students and professionals with the skills to harness the power of advanced artificial intelligence in their practice. This in-depth program covers vital AI technologies, including machine learning, natural language processing, and predictive analytics, all specifically adapted for the healthcare sector. Whether you're a student or a seasoned doctor, nurse, or allied health provider, this course enables you to integrate AI seamlessly into diagnostics, treatment planning, and patient care, thereby enhancing efficiency and precision.</p>

                                <p>At Madas Health, our technical capabilities are significantly bolstered by our key partner, <strong>GTechnologies Pty Ltd</strong>. Specializing in data science and various technological tasks, GTechnologies Pty Ltd plays a crucial role in our operations by applying their expertise in advanced analytics, machine learning, and information technology. Their work in processing and interpreting complex data sets is essential to delivering informed and efficient healthcare solutions. This collaboration allows us to remain at the forefront of technology, ensuring our services are not only current but also predictive and adaptable to future healthcare needs. GTechnologies Pty Ltd's technical prowess complements our medical expertise, fostering a synergy that drives innovation and excellence in our offerings.</p>

                                <p>We are also proud to collaborate with <strong>Rhodes International College</strong>, a reputable institution that supports our vision for innovation in healthcare education through advanced AI and data science training. Rhodes International College provides healthcare students and professionals with opportunities to gain real-world AI experience and expertise, contributing to a future where AI plays a critical role in healthcare transformation.</p>
                                For more details, visit <a href="https://gtechnologies.au/">GTechnologies Pty Ltd</a> and <a href="https://ric.edu.au/">Rhodes International College</a>
                                <div className="questions-section">
                                    <h3>Why is this training needed?</h3>
                                    <p>AI is rapidly transforming healthcare, and professionals must be equipped with the skills to stay ahead of the curve.</p>

                                    <h3>How does it help?</h3>
                                    <p>This training provides hands-on knowledge to improve patient care, streamline workflows, and make data-driven decisions.</p>

                                    <h3>What is the career path?</h3>
                                    <p>Graduates of this course can pursue careers as AI-driven healthcare consultants, data analysts, or innovators in healthcare technology.</p>
                                </div>

                                <p>Madas Health AI has been offering healthcare-specific AI Training & Internships to over 200 students from more than 20 universities across the USA, Australia, and India for the past 14 months via Zoom. Join us to lead the future of healthcare through AI-driven solutions where technology meets expertise for smarter, more personalized patient care.</p>


                                <h5><strong>We have offered training & internships to over 200 students from more than 20 universities across the USA, Australia & India.</strong></h5>
                                <strong><strong>List of AI, ML and Deep Learning projects include:</strong></strong>
                                <ul style={{ fontSize: '1.2em' }}>
                                    <li><strong style={{ fontSize: '0.7em' }}>PathoVision: Automated Pathology Data Extraction and Visualization using Machine Learning.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>X-Ray Insight: Advanced EMR Enhancement through Machine Learning.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>EHR: AI-Driven Excellence in Electronic Health Records with MySQL, DBeaver, Reactjs, Visual Studio, Python, and Machine Learning.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Automated Brain Tumour Segmentation Using Deep Learning: A Comparative Study of U-Net and Ensemble Models.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Automated Detection of Condylar Morphological Abnormalities from CBCT Imaging Using Deep Learning.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Artificial Intelligence (AI) - Diabetes Prediction Model Using Medical History.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Machine Learning (ML) - AI-Based Pneumonia Detection from Chest X-ray Images.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Machine Learning (ML) - Predicting Dementia Risk with Machine Learning.</strong></li>
                                    <li><strong style={{ fontSize: '0.7em' }}>Deep Learning - Alzheimer's Disease Detection Using Deep Learning Techniques.</strong></li>
                                </ul>
                                <strong><strong>List of a few Universities:</strong></strong>
                                <ul>
                                    <li>Indiana University Purdue University Indianapolis, USA</li>
                                    <li>Purdue University, USA</li>
                                    <li>University of Findlay, USA</li>
                                    <li>University of Michigan, USA</li>
                                    <li>Grand Valley State University, USA</li>
                                    <li>University of Texas, USA</li>
                                    <li>University of North Texas, USA</li>
                                    <li>University of Wisconsin-Milwaukee, USA</li>
                                    <li>University of Maryland Baltimore County, Maryland, USA</li>
                                    <li>University of Denver, USA</li>
                                    <li>Belhaven University, USA</li>
                                    <li>Trine University, USA</li>
                                    <li>Northeastern University, Boston, USA</li>
                                    <li>Michigan Technological University, Houghton, MI, USA</li>
                                    <li>State University NY Oswego, USA</li>
                                    <li>California State University, Long Beach, California, USA</li>
                                    <li>Sacred Heart University, USA</li>
                                    <li>Royal Melbourne Institute of Technology, Australia</li>
                                    <li>Monash University, Australia</li>
                                    <li>Gandhi Institute of Technology and Management, Bengaluru, Karnataka, India</li>
                                    <li>Saveetha School of Engineering, Chennai, Tamil Nadu, India</li>
                                    <li>Crescent Institute of Technology, Chennai, Tamil Nadu, India</li>
                                    <li><strong>If anyone or any academic department wishes to contact us, they can reach out via email at Program Director Rajeshwar@madashealth.ai or admin@madashealth.ai</strong></li>
                                </ul>
                            </ul>
                        </div>
                        <div style={logosContainerStyle}>
                            <img style={logoStyle} src={wordCloud} alt="GTechnologies Logo" />
                            <img style={logoStyle} src={RIC} alt="Rhodes International College" />
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default CollabDetails;
