import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import meetSmart from '../icons/meet2.webp'

function InternsPresentPastMeetSmartTwo() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">            
            <div className="text-block-spec">                          
            <ul><h5>Transform Your Meeting Experience</h5>
                MinuteMint AI from MadasHealth.ai is more than just a tool; it's a comprehensive approach to 
                redefining how meetings are managed, documented, and utilized for strategic gains. Experience a new 
                level of efficiency, clarity, and actionable insights in every meeting. 
                <br></br><br></br>
                <h5>Join the Evolution of Smart Meetings</h5>
                Begin your journey towards smarter, more efficient meetings with MinuteMint AI. It's time to redefine
                productivity and strategic insight in your industry. Visit MadasHealth.ai to learn more and start your 
                transformation.
                <br></br><br></br>                
                Step into the future of meeting management with MinuteMint AI. Where every meeting, whether in-person or online,
                becomes a wellspring of efficiency and insight.
              </ul>
            </div>
            <div className="logo-block-spec"><img className="gridlogo-spec" src={meetSmart} alt="Document Accuracy"></img></div>
          </div>          
        </div>               
    </div>
  );
}

export default InternsPresentPastMeetSmartTwo;
