import React from 'react';
import keyStethImage from '../icons/mision.webp'

function AboutUSMissionVisionTwo() {
  return (
    <div className="jumbotron-mv">      

        <div className="heading"><h5>Mission</h5>
            <div className="content">    
            <h5>"Empowering Healthcare, Enhancing Lives"</h5>          
            Our mission is to empower patients and their support networks with advanced, affordable AI technologies. 
            We're committed to reducing the administrative burden on healthcare professionals, enabling them to dedicate more 
            time to direct patient care. By integrating modern medical education into daily practice, we strive to improve health 
            outcomes without imposing financial burdens, ensuring accessible and compassionate healthcare for all.
            </div>
        </div>   

        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>     
    </div>
        
  );
}

export default AboutUSMissionVisionTwo;
