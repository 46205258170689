import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import digChr from '../icons/digChakra.webp';
import wordCloud from '../icons/Logo-medispeak.jpg';
import brainTumor from '../icons/dictate.jpg';
import guidedProj from '../icons/speech-text.jpg';
import aiHealth from '../icons/pathology.webp';
import radio from '../icons/radio.webp';
import ehr from '../icons/ehr.jpg';
import ecg from '../icons/ECG-Deciphered.webp';
import digsan from '../icons/dig-sanj2.webp';
import meetSmart from '../icons/meetsmart.webp';
import shru from '../icons/shu-bharat.webp'

function ProjectDetails() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
    };

    return (
        <div className="testimonials">
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="logo-block-spec"><img className="gridlogo-spec-logo" src={wordCloud} alt="Document Accuracy"></img></div>
                    <div className="text-block-spec">
                        <ul><h5><strong>Introducing MediSpeakAI:</strong> Your Ultimate Clinical Practice Companion</h5>
                            MediSpeakAI is revolutionizing clinical practice with advanced speech-to-text transcription.
                            Say goodbye to manual note-taking as our intelligent assistant effortlessly transcribes patient interactions.
                            It complements healthcare professionals, enhancing diagnostics, reducing errors, boosting productivity, and
                            minimizing complications.<br></br><br></br>
                            MediSpeakAI is multilingual and adaptable, ensuring effectiveness in diverse healthcare settings.
                            Welcome to a future where efficiency and accuracy reign, powered by MediSpeakAI.<br></br><br></br>
                            For more details Visit our <a href="https://medispeak.ai/">website</a>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="text-block-spec">
                        <ul><h5><strong>IntelliDictate:</strong> Revolutionize Healthcare Reporting with Madas Health AI (Automated report writing tasks)</h5>
                            Madas Health AI revolutionizes report writing for specialists, slashing time by 50%. Even for reluctant specialists, it delivers
                            high-quality reports swiftly. This innovation benefits not only specialists but also streamlines junior doctors' and
                            nursing staff's work.<br></br><br></br>
                            Our AI-generated reports are faster, precise, and error-free, transforming healthcare reporting. Choose Mada
                            Health AI for a productivity boost and elevated reporting quality.
                        </ul>
                    </div>
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={brainTumor} alt="Empowering the Surgeon"></img></div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={guidedProj} alt="Operational Prowess"></img></div>
                    <div className="text-block-spec">
                        <ul><h5><strong>From Speech to Prescription:</strong> Leveraging AI for Efficient Medication Management and Patient Communication</h5>
                            The concept of "Smarter Prescriptions" leverages advanced AI technologies like Medispeak AI to revolutionize the prescription process in healthcare.
                            This innovative approach aims to enhance patient safety, treatment efficacy, and overall healthcare quality.
                            Key features include efficient drug history documentation through speech-to-text technology, automating Consumer Medicine Information (CMI)
                            for better patient education, and incorporating over-the-counter medication details into
                            Electronic Health Records (EHRs). <br></br><br></br>
                            AI's ability to analyze extensive datasets aids in optimal drug selection and dosage determination, while its
                            integration with EHRs streamlines workflow. Additionally, AI offers real-time interaction checking across
                            various drug categories and the capability to electronically transmit prescriptions, enhancing the efficiency of
                            the prescription process. The inclusion of compounding medication information and the analysis of lab results
                            further demonstrate AI's role in ensuring a comprehensive and safe
                            approach to medication management.<br></br><br></br>
                            This strategy not only reduces potential errors but also ensures personalized patient care, guided by the latest medical knowledge and
                            enhanced communication.
                        </ul>
                    </div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="text-block-spec">
                        <ul><h5><strong>PathoDx: </strong> Advanced Pathology Results Reading and Analysis</h5>
                            PathoDx is a cutting-edge AI solution that simplifies the reading and analysis of pathology reports for healthcare
                            providers. It addresses the challenge of interpreting complex reports with varying formats, containing crucial
                            health metrics.<br></br><br></br>
                            Particularly valuable for an aging population requiring continuous health monitoring, PathoDx consolidates data,
                            standardizes report formats, generates easy-to-understand summaries, analyzes trends, and issues alerts for
                            critical findings. This AI-powered tool streamlines chronic disease management, enhancing patient care and
                            clinical outcomes.
                        </ul>
                    </div>
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={aiHealth} alt="Evolution in Training"></img></div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={radio} alt="Operational Prowess"></img></div>
                    <div className="text-block-spec">
                        <ul><h5><strong>Introducing ChestVisionAI:</strong> Your Intelligent Radiology Partner</h5>
                            ChestVisionAI is a cutting-edge AI-powered radiology software designed for chest X-ray (CXR) interpretation.
                            It enhances diagnostic accuracy and efficiency by swiftly analyzing CXR images, differentiating normal from
                            abnormal, and identifying various pathologies. <br></br><br></br>
                            This advanced tool empowers healthcare professionals, supporting quicker diagnosis and treatment decisions to
                            improve patient outcomes. ChestVisionAI reflects Madas Health AI's dedication to providing digital solutions
                            that enhance modern healthcare practices.
                        </ul>
                    </div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="text-block-spec">
                        <ul><h5><strong>Innovative AI Applications in Electronic Health Records:</strong> A Modern Healthcare Revolution</h5>
                            Revolutionize your healthcare experience with our state-of-the-art Electronic Health Records (EHR) system,
                            powered by cutting-edge Artificial Intelligence. Our EHR suite features innovative tools like Medispeak AI
                            for simplified medical communication, IntelliDictate for efficient voice-transcribed data entry, PathDx and
                            Chest Vision AI for enhanced diagnostic accuracy, alongside groundbreaking AI-powered medication prescription
                            systems and automated patient booking functionalities. <br></br><br></br>
                            Embrace a new era of healthcare where accuracy, efficiency, and patient engagement are paramount.
                            Experience seamless integration, top-notch data security, and reduced operational costs, all tailored
                            to empower healthcare providers and patients alike. Join us in transforming healthcare delivery into a
                            more patient-centric, efficient, and safer journey, where technology and human expertise blend seamlessly
                            for optimal care.
                        </ul>
                    </div>
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={ehr} alt="Evolution in Training"></img></div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={ecg} alt="Operational Prowess"></img></div>
                    <div className="text-block-spec">
                        <ul><h5><strong>Welcome to ECG Deciphered:</strong> Where we are pioneering the future of cardiac care</h5>
                            Our mission is to make advanced ECG services accessible to everyone, everywhere. By harnessing the power of AI,
                            we offer accurate and efficient ECG interpretations, seamless integration with electronic health records, and
                            state-of-the-art training for healthcare professionals.  <br></br><br></br>
                            Whether in remote rural areas or bustling urban centers, our innovative solutions bridge the gap in cardiac care,
                            ensuring early detection and superior patient outcomes. Join us in our journey to revolutionize cardiac health,
                            one heartbeat at a time.<br></br><br></br>
                            To know more click <a href="https://madashealth.ai/ECGDeciphered "> here</a>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="text-block-spec">
                        <ul><h5><strong>Digital Charaka: </strong> Revolutionizing Healthcare in India with AI-Enabled Clinical Practice Guidelines</h5>
                            <br></br>Revolutionize Indian healthcare with AI-driven Clinical Practice Guidelines (CPGs).<br></br><br></br>
                            <strong>Key Features:</strong><br></br>
                            Standardize healthcare across India.<br></br>
                            Adapt Western CPGs and develop India-specific guidelines.<br></br><br></br>
                            <strong>Advantages:</strong><br></br>
                            Bridge rural-urban healthcare divide.<br></br>
                            Equitable access to quality medical services.<br></br><br></br>
                            <strong>AI Use:</strong><br></br>
                            Improve healthcare quality and safety.<br></br>
                            Reduce treatment variability and costs.<br></br><br></br>
                            <strong>Focus Area:</strong> Enhance family medicine training and practice.<br></br>
                            <strong>Public Health Impact:</strong> Address India's unique health challenges effectively.<br></br>
                            <strong>Family Medicine:</strong> Streamline care, reduce specialist referrals.<br></br><br></br>
                            <strong>Approach:</strong> Combine Western models with local needs analysis.<br></br>
                            <strong>Vision:</strong> Modernize Indian healthcare, align with global standards.
                        </ul>
                    </div>
                    <div className="logo-block-spec"><img className="gridlogo-spec" src={digChr} alt="Evolution in Training"></img></div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="text-block-spec">
                        <ul>
                            <h5><strong>Maximize Your Time with "AssistCareAI":</strong></h5>
                            <li>Say goodbye to lengthy note-taking and save over an hour daily for each support worker.</li>
                            <li>Support workers can manage an average of 7 clients, gaining valuable hours every week.</li>
                            <li>Boost productivity by serving an additional 4-5 clients daily with our game-changing technology.</li>
                            <li>Enhance service delivery and increase revenues through improved efficiency.</li>
                            <li>Reduce fatigue and achieve a healthier work-life balance for a better quality of care.</li>
                        </ul>
                        <ul>
                            <h5><strong>Bill Faster, Earn Quicker:</strong></h5>
                            <li>Experience instant documentation and swift billing processes with "AssistCareAI".</li>
                            <li>Deliver better care and see revenues pouring in faster than ever before.</li>
                            <li>Access vital clinical information instantly through our intuitive chat window.</li>
                            <li>Expand your knowledge seamlessly with layman-friendly clinical insights.</li>
                            <li>Join the revolution in care delivery with Madas Telehealth Pty Ltd. today!</li>
                        </ul>
                    </div>
                    <div className="logo-block-spec">
                        <img className="gridlogo-spec" src={ehr} alt="Evolution in Training" />
                    </div>
                </div>
            </div>
            <div className="step-edu-specialist-main">
                <div className="slide-content-spec">
                    <div className="logo-block-spec">
                        <img className="gridlogo-spec" src={guidedProj} alt="Operational Prowess" />
                    </div>
                    <div className="text-block-spec">
                        <ul>
                            <h5><strong>Welcome to the Future of Wound Care!</strong></h5>
                            <li><strong>Remote Wound Imaging:</strong> Capture high-resolution wound images remotely via smartphones for AI-powered analysis.</li>
                            <li><strong>AI-Powered Analysis:</strong> Analyze wound characteristics (size, depth, tissue type) with precision to aid clinical decisions.</li>
                            <li><strong>Real-Time Monitoring:</strong> Track wound progress in real-time, facilitating timely treatment adjustments.</li>
                            <li><strong>Seamless Integration:</strong> Integrate smoothly with EHR systems, improving workflow and care coordination.</li>
                            <li><strong>Enhanced Patient Engagement:</strong> Empower patients with easy image uploads and reduced clinic visits.</li>
                        </ul>
                        <ul>
                            <h5><strong>Benefits:</strong></h5>
                            <li><strong>Improved Clinical Outcomes:</strong> Enhance treatment effectiveness and patient outcomes.</li>
                            <li><strong>Cost Savings:</strong> Reduce healthcare costs by minimizing unnecessary visits.</li>
                            <li><strong>Accessibility:</strong> Extend specialized wound care to underserved communities.</li>
                            <li><strong>Scalability:</strong> Expand wound care services efficiently with scalable cloud-based technology.</li>
                        </ul>
                    </div>
                </div>
            </div>





                </div>
                );
}

                export default ProjectDetails;
