import React from 'react';
import keyStethImage from '../icons/buisness.webp'; // Import the image based on the given directory structure

function JumbotronOthers() {
    return (
        <div className="jumbotron-mv-medispeak-others">
            <div className="heading">
                <h4><br /><br /></h4> {/* Two lines of space */}
                <h4><strong>Empowering Business Innovation with AI Solutions</strong></h4>
                <div className="content">
                    At MadasHealth, we are dedicated to transforming businesses across sectors with the power of AI.
                    Our innovative solutions are designed to enhance operational efficiency, personalize customer experiences,
                    and drive growth. By leveraging our AI technology, businesses can enjoy streamlined processes,
                    improved decision-making, and enhanced productivity. Our AI solutions are tailored to meet the unique needs of
                    each industry, ensuring that every aspect of your business is optimized for success. With our, companies
                    can anticipate market changes with greater accuracy, offer customized solutions to their customers, and manage
                    resources more effectively. Embrace the future of business with our, where cutting-edge technology and
                    strategic innovation converge to empower your success.
                </div>
            </div>
        </div>
    );
}

export default JumbotronOthers;


