import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo.jpg'

function AboutUsJumbo() {
    return (
        <div className="jumbotron-mv">
            <div className="heading"><h5>About Us</h5>
                <div className="content">
                    MadasHealth AI is revolutionizing India's healthcare with advanced AI solutions, targeting key challenges in the
                    sector. Their innovative suite includes AI-driven speech-to-text applications, telemedicine tools, and smart
                    electronic health records, aimed at enhancing diagnostic accuracy and reducing clinicians' administrative workload.
                    A pivotal part of their strategy is "Digital Sanjeevani," an application empowering healthcare consumers to actively
                    manage their health. Through such technologies, MadasHealth AI is creating a more accessible, efficient, and
                    patient-centered healthcare system in India, focusing on improving health outcomes and reducing costs.
                    <br></br><br></br>
                    Our company has a presence in Sydney, Australia, and you can find more information at<br></br>
                    <a href="https://madashealth.ai ">https://madashealth.ai</a>
                </div>
            </div>
            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="YouTube video placeholder"
                    title="YouTube video player"
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default AboutUsJumbo;
