import React from 'react';
import keyStethImage from '../icons/GTechnology-Logo-remove.png'   // Import the image based on the given directory structure
import madasHealthLogo from '../icons/Logo.jpg'

function CollabJumbo() {
  return (
    <div className="jumbotron-mv-main-collab">
          <div className="heading">AI Training
            <div className="content"></div>
        </div>
        <div className="youtube-player">            
            <img 
                width="560" 
                height="315" 
                src={madasHealthLogo} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
    </div>  
  );
}

export default CollabJumbo;

