import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import medicalImagry from '../icons/imHeal.webp';
import voiceInte from '../icons/patient-empower.webp';
import revClin from '../icons/accessAI.webp';
import medCommu from '../icons/redLoad.webp';
import surgery from '../icons/revMed.webp';
import prevFin from '../icons/prevFin.webp';
import edBridge from '../icons/edBridge.webp';

function SpecialistWhatis() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="testimonials-slider">
      <Slider {...settings}>        
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={voiceInte} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Patient Empowerment:</h5> To elevate patient empowerment and provide support to their loved ones through user-friendly, AI-driven solutions.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={revClin} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Accessible AI Excellence:</h5> To make advanced AI technology in healthcare accessible and affordable, ensuring quality care is not a luxury.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medCommu} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Reducing Administrative Load:</h5> To minimize doctors' administrative tasks, allowing them to focus more on direct patient care and interaction.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={surgery} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Revolutionizing Medical Training:</h5>  To transform medical education with AI-enabled real-time training, particularly in family medicine, making it more responsive and relevant to current healthcare challenges.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={medicalImagry} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Improving Health Outcomes:</h5> To enhance health outcomes within the economic capabilities of diverse communities, making quality healthcare a reality for all.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={prevFin} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Preventing Financial Hardship:</h5> To actively work towards reducing the financial impact of medical expenses, aiming to prevent medical bankruptcies.</p>
              </div>
            </div>
        </div>
        <div className="step-edu-specialist-main">
            <div className="slide-content-spec">
              <div className="logo-block-spec"><img className="gridlogo-spec-slider" src={edBridge} alt="Voice-Driven Clinical Documentation"></img></div>
              <div className="text-block-spec">              
                <p><h5>Educational Bridging:</h5> To bridge the gap in family medicine education in regions such as India, leveraging AI-enabled innovations to provide comprehensive and up-to-date medical knowledge.</p>
              </div>
            </div>
        </div>
      </Slider>
    </div>
  );
}

export default SpecialistWhatis;
