import React from 'react';
import madasHealthLogo from '../icons/home-image.webp'  // Import the image based on the given directory structure
import madasHealthMain from '../icons/home-india.webp'

function JumbotronHome() {
  return (
    <div className="jumbotron-home">
        <div className="heading-home"><strong>MADAS HEALTH ARTIFICIAL INTELLIGENCE (AI) INDIA</strong>
              <div className="content-home">Empowering Healthcare Professionals and Patients with Cutting-Edge AI Applications from Madas Telehealth Pty Ltd.</div>
            <div className="youtube-player">            
            <img 
                width="900" 
                height="500" 
                src={madasHealthMain} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>
        </div>
        
    </div>        
  );
}

export default JumbotronHome;

