import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fontPage from '../icons/manCome.png';
import wordCloud from '../icons/digital-sanjeevani.gif';
import brainTumor from '../icons/dictate.jpg';
import guidedProj from '../icons/speech-text.jpeg';
import aiHealth from '../icons/pathology.webp';
import radio from '../icons/radio.webp';
import shru from '../icons/shu-bharat.webp'
import digsan from '../icons/dig-sanj2.webp';

function InternsPresentPast() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 milliseconds
    };

    return (
        <>
            <div className="testimonials">
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={shru} alt="Document Accuracy" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2><strong>Sushruta NirogiBharat:</strong> Your Health, Our Mission</h2>
                                <p>
                                    Empowering Indians with AI-enhanced health education tailored to diverse needs. We support national health initiatives by providing data-driven insights and preventive care solutions. Our platform promotes health literacy through accessible, multilingual resources, ensuring that everyone can make informed health decisions. By fostering a supportive community, we aim to empower individuals with the knowledge they need for healthier lives. Sushruta NirogiBharat is trusted by healthcare experts for its reliable, personalized health information, making it a valuable resource for improving public health across India.
                                </p>
                                <li>
                                    <strong>Revolutionary Platform:</strong><br />
                                    Led by Dr. Eshwar Madas, offering AI-enhanced, multilingual health education online.<br /><br />
                                </li>
                                <li>
                                    <strong>Personalized Health Information:</strong><br />
                                    AI tailors health info to your needs in multiple Indian languages.<br /><br />
                                </li>
                                <li>
                                    <strong>Supporting National Health:</strong><br />
                                    Aligns with government efforts for a healthier India, leveraging data for public health insights.<br /><br />
                                </li>
                                <li>
                                    <strong>Cost-Saving and Accessible:</strong><br />
                                    Promotes preventive care, aiming to make healthcare more affordable and accessible.<br /><br />
                                </li>
                                <li>
                                    <strong>Comprehensive Resources:</strong><br />
                                    Access articles, videos, tools for better health understanding.<br /><br />
                                </li>
                                <li>
                                    <strong>Community Support:</strong><br />
                                    Engage with a supportive community of health enthusiasts and professionals.<br /><br />
                                </li>
                                <li>
                                    <strong>Real-Time Health Alerts:</strong><br />
                                    Receive timely notifications about health advisories and preventive measures.<br /><br />
                                </li>
                                <li>
                                    <strong>Track Your Health:</strong><br />
                                    Monitor health metrics and progress with personalized dashboards.<br /><br />
                                </li>
                                <li>
                                    <strong>Trusted by Experts:</strong><br />
                                    Endorsed by leading healthcare professionals and institutions for accuracy and reliability.<br /><br />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>





           
            <div className="testimonials">
                <div className="step-edu-specialist-main">
                    <div className="slide-content-spec">
                        <div className="logo-block-spec">
                            <img className="gridlogo-spec" src={wordCloud} alt="Document Accuracy" />
                        </div>
                        <div className="text-block-spec">
                            <ul>
                                <h2><strong>Digital Sanjeevani:</strong> Your 24/7 Healthcare Buddy</h2>
                                Imagine having a virtual doctor at your fingertips, ready to assist you round the clock.
                                Digital Sanjeevani is a user-friendly mobile app and hotline that listens to your health concerns,
                                offers immediate help for emergencies, and provides reassurance for non-urgent issues.<br /><br />
                                Best of all, if publicly funded, this service could be available to you for free.
                                Say goodbye to unnecessary trips to the doctor, reduce anxiety, and protect yourself from unwarranted medical
                                expenses. With research-backed success stories, Digital Sanjeevani is revolutionizing healthcare accessibility,
                                offering guidance, and peace of mind whenever you need it.
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InternsPresentPast;
