import React from 'react';
import keyStethImage from '../icons/vision.webp'

function AboutUSMissionVision() {
  return (
    <div className="jumbotron-mv">
        <div className="youtube-player-mv">            
            <img 
                width="560" 
                height="315" 
                src={keyStethImage} 
                alt="YouTube video placeholder" 
                title="YouTube video player" 
                style={{overflow:'hidden', maxWidth:'100%', maxHeight:'100%', objectFit: 'contain'}}
            />
        </div>

        <div className="heading"><h5>Vision</h5>
            <div className="content">    
            <h5>"Technology for a Healthier Tomorrow"</h5>        
            At Madas Health AI, our vision is to revolutionize healthcare through the power of AI, focusing on human-centric 
            solutions. We aspire to make cutting-edge technology a beacon of hope for patients, families, and healthcare providers, 
            fostering a future where technology serves humanity, leading to healthier lives and communities.
            </div>
        </div>        
    </div>
        
  );
}

export default AboutUSMissionVision;
